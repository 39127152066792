import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import grafico from '../images/webp/grafico.webp';
import city from '../images/webp/city2.webp';

import cliente1 from '../images/webp/cliente1.webp';
import cliente2 from '../images/webp/unnamed (2).png';
import cliente3 from '../images/webp/cliente4.webp';
import cliente5 from '../images/webp/cliente5.webp';
import cliente7 from '../images/webp/cliente7.webp';
import cliente8 from '../images/webp/cliente8.webp';

import gestion from '../images/new Icons/redes1.png';
import estrategia from '../images/new Icons/ads2.png';
import contenido from '../images/new Icons/contenido1.png';

function HomePage() {
  const [hasViewedSection1, setHasViewedSection1] = useState(false);
  const [hasViewedSection2, setHasViewedSection2] = useState(false);
  const [hasViewedSection3, setHasViewedSection3] = useState(false);

  const { ref: refSection1, inView: inViewSection1 } = useInView({
    threshold: 0.1,
  });
  const { ref: refSection2, inView: inViewSection2 } = useInView({
    threshold: 0.1,
  });
  const { ref: refSection3, inView: inViewSection3 } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (inViewSection1 && !hasViewedSection1) {
      setHasViewedSection1(true);
    }
  }, [inViewSection1, hasViewedSection1]);

  useEffect(() => {
    if (inViewSection2 && !hasViewedSection2) {
      setHasViewedSection2(true);
    }
  }, [inViewSection2, hasViewedSection2]);

  useEffect(() => {
    if (inViewSection3 && !hasViewedSection3) {
      setHasViewedSection3(true);
    }
  }, [inViewSection3, hasViewedSection3]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="font-nunito">

      {/* Hero Section */}
      <section className="relative h-[70vh] bg-black">
        <img src={city} alt="City" className="absolute inset-0 w-full h-full object-cover z-0" loading="lazy" />
        <div className="absolute inset-0 z-0 bg-black/30 backdrop-blur-[1px]" />
        <div className="absolute inset-0 z-0 bg-gradient-to-b from-transparent to-black opacity-20" />

        {/* Ajuste de altura en móviles con h-auto */}
        <section className="flex flex-col items-center justify-center relative h-[70vh] mx-auto px-2 sm:px-4 overflow-hidden mb-8">

          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="text-white text-center z-10 w-full max-w-[1400px] px-4 sm:px-8 md:px-16 lg:px-56"
          >
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5, delay: 0.5 }}
              className="text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold mb-6 md:mb-10 text-white font-raleway"
            >
              Haz que tu marca resuene en Redes Sociales
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5, delay: 1 }}
              className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-8 md:mb-8 font-raleway font-semibold"
            >
              Conecta con tu audiencia, multiplica tu impacto.
            </motion.p>
            <Link to="/contact">
              <motion.button
                initial={{ opacity: 0, y: 20, transition: { duration: 1.5, delay: 1.5 } }}
                animate={{ opacity: 1, y: 0, transition: { duration: 1.5, delay: 1.5 } }}
                whileHover={{ scale: 1.05, boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)', transition: { duration: 0.5 } }}
                transition={{ duration: 0.5 }}
                whileTap={{ scale: 0.95, transition: { duration: 0.5 } }}
                className="hover:bg-rose-700 bg-rose-600 focus:outline-none focus:ring-2 focus:ring-red-500 text-white font-semibold py-6 sm:py-7 px-8 sm:px-8 text-sm sm:text-md rounded-full relative z-10 mt-4 mb-2"
                aria-label="Impulsar el negocio"
              >
                IMPULSA TU NEGOCIO
              </motion.button>
            </Link>
          </motion.div>
        </section>
      </section>

      {/* División Ondulada */}
      <div className="relative bg-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 110"
          className="absolute bottom-0 left-0 w-full"
        >
          <path
            fill="#ffffff"
            fillOpacity="1"
            d="M0,90 C360,120 1080,40 1440,80 L1440,320 L0,320 Z"
          />
        </svg>
      </div>

      {/* Clientes Section */}
      <section className="py-6 bg-white">
        <section className="py-12 bg-white" ref={refSection3}>
          <div className="container mx-auto text-center">
            <motion.h2
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="font-raleway text-2xl sm:text-3xl md:text-4xl font-bold mb-8"
            >
              Marcas que confían en nosotros
            </motion.h2>

            <div className="flex flex-wrap justify-center">
              <motion.img
                src={cliente1}
                alt="Logo Cliente 1 - [Sabadell Cup]"
                className="h-20 mx-4 sm:mx-6 md:mx-8 my-2"
                loading="lazy"
                initial={{ opacity: 0, transition: { duration: 1 } }}
                animate={hasViewedSection3 ? { opacity: 1, transition: { duration: 1 } } : { opacity: 0, transition: { duration: 1 } }}
              />
              <motion.img
                src={cliente2}
                alt="Logo Cliente 1 - [Pizzeria Verona]"
                className="h-20 mx-4 sm:mx-6 md:mx-8 my-2"
                initial={{ opacity: 0, transition: { duration: 1 } }}
                animate={hasViewedSection3 ? { opacity: 1, transition: { duration: 1 } } : { opacity: 0, transition: { duration: 1 } }}
              />
              <motion.img
                src={cliente3}
                alt="Logo Cliente 1 - [Bingrill]"
                className="h-20 mx-4 sm:mx-6 md:mx-8 my-2"
                loading="lazy"
                initial={{ opacity: 0, transition: { duration: 1 } }}
                animate={hasViewedSection3 ? { opacity: 1, transition: { duration: 1 } } : { opacity: 0, transition: { duration: 1 } }}
              />
              <motion.img
                src={cliente5}
                alt="Logo Cliente 5 - [Mimos Born]"
                className="h-20 mx-4 sm:mx-6 md:mx-8 my-2"
                loading="lazy"
                initial={{ opacity: 0, transition: { duration: 1 } }}
                animate={hasViewedSection3 ? { opacity: 1, transition: { duration: 1 } } : { opacity: 0, transition: { duration: 1 } }}
              />
              <motion.img
                src={cliente7}
                alt="Logo Cliente 7 - [Standecor]"
                className="h-20 mx-4 sm:mx-6 md:mx-8 my-2"
                loading="lazy"
                initial={{ opacity: 0, transition: { duration: 1 } }}
                animate={hasViewedSection3 ? { opacity: 1, transition: { duration: 1 } } : { opacity: 0, transition: { duration: 1 } }}
              />
              <motion.img
                src={cliente8}
                alt="Logo Cliente 8 - [101Pisos]"
                className="h-20 mx-4 sm:mx-6 md:mx-8 my-2"
                loading="lazy"
                initial={{ opacity: 0, transition: { duration: 1 } }}
                animate={hasViewedSection3 ? { opacity: 1, transition: { duration: 1 } } : { opacity: 0, transition: { duration: 1 } }}
              />
            </div>
          </div>
        </section>

        {/* Sobre Nosotros Section */}
        <section className="py-20 bg-white" ref={refSection1}>
          <div className="container mx-auto flex flex-col-reverse md:flex-row items-center justify-center px-4">
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={hasViewedSection1 ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }} // Animación condicional
              transition={{ duration: 1 }}
              className="text-center md:text-left md:w-1/3 mb-8 md:mb-0" // Agrega un margen inferior en pantallas pequeñas
            >
              <h2 className="text-4xl md:text-5xl font-extrabold mb-6 font-raleway leading-relaxed">Expertos en marketing digital</h2>
              <p className="text-lg md:text-xl mb-4 leading-relaxed"> {/* Ajusta el tamaño del texto */}
                Media4Sports es una empresa internacional de marketing especializada en la gestión integral de redes sociales.
                Ayudamos a empresas de diversas industrias a construir una presencia digital sólida y efectiva.
              </p>
              <Link to="/history">
                <motion.button
                  aria-label="Contactar a Media4Sports"
                  whileHover={{ scale: 1.1, boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)', transition: { duration: 0.5 } }}
                  whileTap={{ scale: 0.9, transition: { duration: 0.5 } }}
                  transition={{ duration: 0.5 }}
                  className="hover:bg-rose-700 bg-rose-600 focus:outline-none focus:ring-2 focus:ring-red-500 text-white font-lightbold py-4 sm:py-5 px-6 sm:px-10 text-sm sm:text-md rounded-full relative z-10 mt-2 mb-2"
                >
                  Conocenos Mejor
                </motion.button>
              </Link>
            </motion.div>

            <motion.img
              initial={{ opacity: 0, x: 50 }}
              animate={hasViewedSection1 ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }} // Animación condicional
              transition={{ duration: 1 }}
              src={grafico}
              alt="Cohete"
              className="h-60 md:h-80 md:ml-32 mb-5 mt-5"
              loading="lazy"
            />
          </div>
        </section>
      </section>

      {/* Servicios Section */}
      <section className="py-16 bg-gray-100" ref={refSection2}>
        <div className="container text-center mx-auto">
          <motion.h2
            className="text-5xl font-extrabold font-raleway mb-4"
            initial={{ opacity: 0, y: 50 }}
            animate={hasViewedSection2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 1 }}
          >
            Servicios a tu medida
          </motion.h2>
          <motion.p
            className="text-xl mb-12"
            initial={{ opacity: 0, y: 50 }}
            animate={hasViewedSection2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 1 }}
          >
            Nuestro equipo de expertos te ayudará a alcanzar tus objetivos en redes sociales.
          </motion.p>

          <div className="flex flex-wrap justify-center">

            {/* Servicio 1 */}
            <motion.div
              className="w-full md:w-80 px-4 mb-6"
              initial={{ opacity: 0, y: 100 }}
              animate={hasViewedSection2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
              transition={{ duration: 1, delay: 0.2 }}
            >
              <div className="bg-white shadow-lg rounded-3xl p-6 transform hover:scale-105 transition-transform duration-500">
                <img src={gestion} alt="Estrategia Digital" className="h-16 mx-auto mb-8" loading="lazy" />
                <h3 className="font-raleway text-2xl font-extrabold mb-2 text-left ml-3 mt-8">Gestión de Redes Sociales</h3>
                <p className="text-gray-600 text-lg text-left ml-3 mt-4 mb-10">
                  Descubre cómo una gestión estratégica puede transformar tus redes sociales en una herramienta poderosa de conexión y crecimiento para tu marca.
                </p>
                <Link to="/services" className="text-gray-500 hover:text-red-500 font-lightbold">
                  Descubre más
                </Link>
              </div>
            </motion.div>

            {/* Servicio 2 */}
            <motion.div
              className="w-full md:w-80 px-4 mb-6"
              initial={{ opacity: 0, y: 100 }}
              animate={hasViewedSection2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
              transition={{ duration: 1, delay: 0.4 }}
            >
              <div className="bg-white shadow-lg rounded-3xl p-6 transform hover:scale-105 transition-transform duration-500">
                <img src={estrategia} alt="Estrategia Digital" className="h-16 mx-auto mb-8" loading="lazy" />
                <h3 className="font-raleway text-2xl font-extrabold mb-2 text-left ml-3 mt-8">Estrategias de Google Ads</h3>
                <p className="text-gray-600 text-lg text-left ml-3 mt-4 mb-10">
                  Conecta con tu audiencia ideal y maximiza tu retorno de inversión con campañas optimizadas en Google Ads. Aprende más sobre cómo hacemos que cada clic cuente.
                </p>
                <Link to="/services" className="text-gray-500 hover:text-red-500 font-lightbold">
                  Descubre más
                </Link>
              </div>
            </motion.div>

            {/* Servicio 3 */}
            <motion.div
              className="w-full md:w-80 px-4 mb-6"
              initial={{ opacity: 0, y: 100 }}
              animate={hasViewedSection2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
              transition={{ duration: 1, delay: 0.9 }}
            >
              <div className="bg-white shadow-lg rounded-3xl p-6 transform hover:scale-105 transition-transform duration-500">
                <img src={contenido} alt="Estrategia Digital" className="h-16 mx-auto mb-8" loading="lazy" />
                <h3 className="font-raleway text-2xl font-extrabold mb-2 text-left ml-3 mt-8">Producción de Contenido</h3>
                <p className="text-gray-600 text-lg text-left ml-3 mt-4 mb-10">
                  Conquista a tu audiencia con contenido que cuenta tu historia de manera auténtica y cautivadora. Descubre nuestro enfoque creativo y personalizado.
                </p>
                <Link to="/services" className="text-gray-500 hover:text-red-500 font-lightbold">
                  Descubre más
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Contacto Section */}
      <section className="py-8 bg-white">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-4">¿Listo para llevar tu marca al siguiente nivel?</h2>
          <Link to="/contact">
            <motion.button
              aria-label="Contactar a Media4Sports"
              whileHover={{ scale: 1.1, boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)', transition: { duration: 0.5 } }}
              whileTap={{ scale: 0.9, transition: { duration: 0.5 } }}
              transition={{ duration: 0.5 }}
              className="hover:bg-rose-700 bg-rose-600 focus:outline-none focus:ring-2 focus:ring-red-500 text-white font-lightbold py-4 sm:py-5 px-6 sm:px-10 text-sm sm:text-md rounded-full relative z-10 mt-2 mb-2"
            >
              Contactanos
            </motion.button>
          </Link>
        </div>
      </section>

    </div>
  );
}

export default HomePage;