import React, { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import fullLogo from '../images/webp/completeLogo.webp';
import roundLogo from '../images/webp/roundlogo.webp';
import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <header className="bg-white">
      <nav aria-label="Global" className="mx-auto flex max-w-6xl items-center justify-between p-5 lg:px-8">
        <div className="flex lg:flex-1">
          <Link to='/' className="-m-1.5 p-1.5">
            <span className="sr-only">Media4Sports</span>
            <img
              alt="Media4Sports Logo"
              src={fullLogo}
              className="h-10 w-auto"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 hover:text-rose-700 hover:scale-110 transition-transform duration-200 active:scale-95"
          >
            <span className="sr-only">Abrir menú principal</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          <div className="hidden lg:flex lg:gap-x-12">
            <Link
              to="/"
              className={`text-md font-nunito font-semibold ${location.pathname === '/' ? 'text-rose-700' : 'text-black'} hover:text-rose-700 hover:scale-110 transition-transform duration-200`}
            >
              Inicio
            </Link>
            <Link
              to="/services"
              className={`text-md font-nunito font-semibold ${location.pathname === '/services' ? 'text-rose-700' : 'text-black'} hover:text-rose-700 hover:scale-110 transition-transform duration-200`}
            >
              Servicios
            </Link>
            <Link
              to="/history"
              className={`text-md font-nunito font-semibold ${location.pathname === '/history' ? 'text-rose-700' : 'text-black'} hover:text-rose-700 hover:scale-110 transition-transform duration-200`}
            >
              Empresa
            </Link>
            <Link
              to="/contact"
              className={`text-md font-nunito font-semibold ${location.pathname === '/contact' ? 'text-rose-700' : 'text-black'} hover:text-rose-700 hover:scale-110 transition-transform duration-200`}
            >
              Contacto
            </Link>
          </div>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-4">
          <a href="https://www.instagram.com/media4sports_/" className="text-black hover:text-rose-700 hover:scale-110 transition-transform duration-200">
            <FontAwesomeIcon icon={faInstagram} className="h-6 w-6" />
          </a>
          <a href="https://www.instagram.com/media4sports_/" className="text-black hover:text-rose-700 hover:scale-110 transition-transform duration-200">
            <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
          </a>
          <a href="https://www.instagram.com/media4sports_/" className="text-black hover:text-rose-700 hover:scale-110 transition-transform duration-200">
            <FontAwesomeIcon icon={faTwitter} className="h-6 w-6" />
          </a>
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between mb-11">
            <Link to='/' onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Media4Sports</span>
              <img
                alt="Media4Sports Logo"
                src={roundLogo}
                className="h-10 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 hover:text-rose-700 hover:scale-110 transition-transform duration-200 active:scale-95"
            >
              <span className="sr-only">Cerrar menú</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <Link
                to="/"
                onClick={() => setMobileMenuOpen(false)}
                className={`font-nunito -mx-3 block rounded-lg px-3 py-2 text-base/7 font-lightbold ${location.pathname === '/' ? 'text-rose-700 font-semibold' : 'text-gray-900'} hover:bg-gray-50`}
              >
                Inicio
              </Link>
              <Link
                to="/services"
                onClick={() => setMobileMenuOpen(false)}
                className={`font-nunito -mx-3 block rounded-lg px-3 py-2 text-base/7 font-lightbold ${location.pathname === '/services' ? 'text-rose-700 font-semibold' : 'text-gray-900'} hover:bg-gray-50`}
              >
                Servicios
              </Link>
              <Link
                to="/history"
                onClick={() => setMobileMenuOpen(false)}
                className={`font-nunito -mx-3 block rounded-lg px-3 py-2 text-base/7 font-lightbold ${location.pathname === '/history' ? 'text-rose-700 font-semibold' : 'text-gray-900'} hover:bg-gray-50`}
              >
                Empresa
              </Link>
              <Link
                to="/contact"
                onClick={() => setMobileMenuOpen(false)}
                className={`font-nunito -mx-3 block rounded-lg px-3 py-2 text-base/7 font-lightbold ${location.pathname === '/contact' ? 'text-rose-700 font-semibold' : 'text-gray-900'} hover:bg-gray-50`}
              >
                Contacto
              </Link>
              <div className="lg:flex lg:flex-1 lg:justify-end space-x-4">
                <a href="https://www.instagram.com/media4sports_/" className="text-black hover:text-rose-700 hover:scale-110 transition-transform duration-200">
                  <FontAwesomeIcon icon={faInstagram} className="h-6 w-6 mt-4" />
                </a>
                <a href="https://www.instagram.com/media4sports_/" className="text-black hover:text-rose-700 hover:scale-110 transition-transform duration-200">
                  <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
                </a>
                <a href="https://www.instagram.com/media4sports_/" className="text-black hover:text-rose-700 hover:scale-110 transition-transform duration-200">
                  <FontAwesomeIcon icon={faTwitter} className="h-6 w-6" />
                </a>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}