import { useEffect } from 'react';

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8 font-nunito">
      <div className="mx-auto max-w-3xl text-left">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-8">Política de Privacidad</h1>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">1. Introducción</h2>
          <p className="text-gray-600">
            Tu privacidad es importante para nosotros. Esta Política de Privacidad describe cómo recopilamos, usamos, protegemos y compartimos tu información personal cuando interactúas con nuestro sitio web, servicios y productos. Al acceder o utilizar nuestros servicios, aceptas las prácticas descritas en esta política.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">2. Información que Recopilamos</h2>
          <p className="text-gray-600">
            Podemos recopilar la siguiente información personal cuando la proporcionas a través de nuestro formulario de contacto, al suscribirte a nuestros boletines o utilizar nuestros servicios:
          </p>
          <ul className="list-disc list-inside text-gray-600">
            <li>Nombre y apellidos</li>
            <li>Correo electrónico</li>
            <li>Mensaje y consultas enviadas a través de los formularios de contacto</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">3. Uso de la Información</h2>
          <p className="text-gray-600">
            Utilizamos la información recopilada para los siguientes fines:
          </p>
          <ul className="list-disc list-inside text-gray-600">
            <li>Responder a tus consultas y solicitudes.</li>
            <li>Mejorar nuestros servicios y personalizar tu experiencia.</li>
            <li>Enviarte comunicaciones relacionadas con nuestros productos y servicios (solo si has dado tu consentimiento).</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">4. Protección de la Información</h2>
          <p className="text-gray-600">
            Tomamos medidas razonables para proteger tu información personal y evitar el acceso no autorizado, la alteración, divulgación o destrucción de la misma. Sin embargo, ningún método de transmisión de datos a través de Internet es 100% seguro, por lo que no podemos garantizar la seguridad absoluta.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">5. Compartir tu Información</h2>
          <p className="text-gray-600">
            No vendemos ni alquilamos tu información personal a terceros. Sin embargo, podemos compartir tu información con proveedores de servicios o socios para el propósito de ejecutar nuestras operaciones, tales como el envío de correos electrónicos o el soporte en marketing. Estos terceros están obligados a proteger tu información de acuerdo con esta política.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">6. Derechos del Usuario</h2>
          <p className="text-gray-600">
            Tienes derecho a acceder, corregir, actualizar o solicitar la eliminación de tu información personal en cualquier momento. Si deseas ejercer estos derechos o tienes alguna pregunta sobre el uso de tu información, por favor contáctanos utilizando los detalles proporcionados en esta política.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">7. Uso de Cookies</h2>
          <p className="text-gray-600">
            Utilizamos cookies para mejorar la experiencia del usuario en nuestro sitio web, analizar su uso y personalizar el contenido. Puedes configurar tu navegador para rechazar cookies, pero ten en cuenta que algunas funciones del sitio podrían no estar disponibles si las rechazas.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">8. Enlaces a Terceros</h2>
          <p className="text-gray-600">
            Nuestro sitio puede contener enlaces a otros sitios web de interés. No somos responsables de las políticas de privacidad ni del contenido de dichos sitios. Te recomendamos leer sus políticas de privacidad antes de proporcionarles información personal.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">9. Modificaciones a esta Política</h2>
          <p className="text-gray-600">
            Podemos actualizar esta Política de Privacidad en cualquier momento. Las modificaciones se publicarán en esta página con la fecha de la última actualización. Te recomendamos revisar esta página periódicamente para estar al tanto de los cambios.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">10. Contacto</h2>
          <p className="text-gray-600">
            Si tienes alguna pregunta o inquietud sobre esta Política de Privacidad, o si deseas ejercer tus derechos sobre tu información personal, por favor contáctanos a través de los siguientes métodos:
          </p>
          <ul className="list-disc list-inside text-gray-600">
            <li><strong>Correo electrónico</strong>: info.media4sports@gmail.com</li>
            <li><strong>Teléfono</strong>: +34 638 061 776</li>
            <li><strong>Dirección</strong>: Calle Orient 78, 3o 8a, Sant Cugat del Vallès, 08173, España</li>
          </ul>
          <p className="text-gray-600">Fecha de última actualización: [Fecha]</p>
        </section>
      </div>
    </div>
  );
}
