import { useEffect, useState } from 'react';
import { ArrowUpIcon } from '@heroicons/react/20/solid';

export default function ScrollUp() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-8 left-8 p-3 bg-rose-600 rounded-full text-white shadow-lg hover:bg-rose-700 focus:outline-none transition-transform z-50 duration-300 ease-out transform ${
        isVisible ? 'translate-y-0' : 'translate-y-20'
      }`}
    >
      <ArrowUpIcon className="h-6 w-6" />
    </button>
  );
}