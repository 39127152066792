import { useEffect } from 'react';

export default function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8 font-nunito">
      <div className="mx-auto max-w-3xl text-left">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-8">Términos y Condiciones</h1>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">1. Introducción</h2>
          <p className="text-gray-600">
            Estos Términos y Condiciones regulan el acceso y uso de este sitio web. Al acceder o usar nuestra web, aceptas cumplir con estos términos. Si no estás de acuerdo, te recomendamos no utilizar este sitio.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">2. Servicios Ofrecidos</h2>
          <p className="text-gray-600">
            Este sitio está destinado a proporcionar información sobre los servicios ofrecidos por nuestra empresa, incluyendo la gestión de redes sociales, creación de contenido, y otros servicios de marketing digital especializados. Si deseas más detalles sobre nuestros servicios, por favor contáctanos.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">3. Uso del Sitio Web</h2>
          <p className="text-gray-600">
            Se concede una licencia limitada para acceder a este sitio únicamente para uso personal y no comercial. No se permite:
          </p>
          <ul className="list-disc list-inside text-gray-600">
            <li>Descargar, modificar o copiar el contenido sin nuestro consentimiento expreso por escrito.</li>
            <li>Utilizar el sitio de manera que pueda causar daño, interrupción o limitación en su funcionalidad.</li>
            <li>Realizar cualquier actividad que vulnere los derechos de propiedad intelectual de <strong>Media4Sports</strong>.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">4. Propiedad Intelectual</h2>
          <p className="text-gray-600">
            Todos los contenidos de este sitio, incluyendo texto, gráficos, logotipos, imágenes, videos y software, son propiedad exclusiva de <strong>Media4Sports</strong> o sus licenciantes y están protegidos por derechos de propiedad intelectual. El uso no autorizado de estos contenidos está prohibido.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">5. Enlaces a Terceros</h2>
          <p className="text-gray-600">
            Este sitio puede incluir enlaces a otras páginas web. No somos responsables del contenido o las prácticas de privacidad de dichos sitios. El acceso y uso de sitios de terceros es bajo tu propio riesgo.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">6. Política de Privacidad</h2>
          <p className="text-gray-600">
            Tu privacidad es importante para nosotros. Por favor, revisa nuestra <a href="/privacy-policy" className="text-rose-600 underline">Política de Privacidad</a> para entender cómo recopilamos, usamos y protegemos tu información personal.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">7. Limitación de Responsabilidad</h2>
          <p className="text-gray-600">
            No nos hacemos responsables por daños directos, indirectos, incidentales o consecuentes que resulten del uso o la imposibilidad de uso del sitio. Esto incluye, entre otros, daños por pérdida de beneficios, datos o cualquier otro tipo de perjuicio económico.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">8. Cambios en los Términos y Condiciones</h2>
          <p className="text-gray-600">
            Nos reservamos el derecho a modificar estos Términos y Condiciones en cualquier momento. Las modificaciones serán efectivas desde el momento de su publicación en este sitio web. Te recomendamos revisar estos términos periódicamente para estar al tanto de cualquier cambio.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">9. Ley Aplicable y Jurisdicción</h2>
          <p className="text-gray-600">
            Estos Términos y Condiciones se regirán por las leyes de España. Cualquier disputa relacionada con el uso de este sitio será sometida a la jurisdicción exclusiva de los tribunales de [ciudad/país].
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">10. Contacto</h2>
          <p className="text-gray-600">
            Si tienes alguna pregunta sobre estos Términos y Condiciones, puedes ponerte en contacto con nosotros a través de:
          </p>
          <ul className="list-disc list-inside text-gray-600">
            <li><strong>Correo electrónico</strong>: info.media4sports@gmail.com</li>
            <li><strong>Teléfono</strong>: +34 638 061 776</li>
            <li><strong>Dirección</strong>: Calle Orient 78, 3o 8a, Sant Cugat del Vallès, 08173, España</li>
          </ul>
          <p className="text-gray-600">Fecha de última actualización: [Fecha]</p>
        </section>

      </div>
    </div>
  );
}
