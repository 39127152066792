import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import teamPhoto from '../images/webp/roundlogo.webp';
import visionIcon from '../images/new Icons/brujula.png';
import missionIcon from '../images/new Icons/telescopio.png';
import valuesIcon from '../images/new Icons/balanza.png';
import city from '../images/webp/city2.webp';

function History() {
  const [hasViewedSection1, setHasViewedSection1] = useState(false);
  const [hasViewedSection2, setHasViewedSection2] = useState(false);

  const { ref: refSection1, inView: inViewSection1 } = useInView({
    threshold: 0.1,
  });
  const { ref: refSection2, inView: inViewSection2 } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (inViewSection1 && !hasViewedSection1) {
      setHasViewedSection1(true);
    }
  }, [inViewSection1, hasViewedSection1]);

  useEffect(() => {
    if (inViewSection2 && !hasViewedSection2) {
      setHasViewedSection2(true);
    }
  }, [inViewSection2, hasViewedSection2]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="font-nunito">

      {/* Header Section */}
      <section className="relative h-[70vh] bg-black">
        <img src={city} alt="City" className="absolute inset-0 w-full h-full object-cover z-0" loading="lazy"/>
        <div className="absolute inset-0 z-0 bg-black/30 backdrop-blur-[1px]" />
        <div className="absolute inset-0 z-0 bg-gradient-to-b from-transparent to-black opacity-100" />

        {/* Ajuste de altura en móviles con h-auto */}
        <section className="flex flex-col items-center justify-center relative h-[70vh] mx-auto px-2 sm:px-4 overflow-hidden mb-8">

          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="text-white text-center z-10 w-full max-w-[1400px] px-4 sm:px-8 md:px-16 lg:px-56"
          >
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5, delay: 0.5 }}
              className="text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold mb-6 md:mb-10 text-white font-raleway"
            >
              Conoce a <span className="text-rose-700">Media</span>4Sports
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5, delay: 1 }}
              className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-8 md:mb-8 font-raleway font-semibold"
            >
              Impulsando marcas a través de la innovación digital.
            </motion.p>
          </motion.div>
        </section>
      </section>

      {/* Curved Background */}
      <div className="relative bg-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 110"
          className="absolute bottom-0 left-0 w-full"
        >
          <path
            fill="#ffffff"
            fillOpacity="1"
            d="M0,90 C360,120 1080,40 1440,80 L1440,320 L0,320 Z"
          />
        </svg>
      </div>

      {/* Historia y Misión */}
      <section className="py-20 bg-white" ref={refSection1}>
        <div className="container mx-auto flex flex-col-reverse md:flex-row items-center justify-center px-4">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={hasViewedSection1 ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }} // Animación condicional
            transition={{ duration: 1 }}
            className="text-center md:text-left md:w-1/3 mb-8 md:mb-0" // Agrega un margen inferior en pantallas pequeñas
          >
            <h2 className="text-4xl md:text-5xl font-extrabold mb-6 font-raleway leading-relaxed">Nuestra Historia</h2>
            <p className="text-lg md:text-xl mb-4 leading-relaxed"> {/* Ajusta el tamaño del texto */}
              En Media4Sports, desarrollamos estrategias digitales que van más allá de la publicidad tradicional, ayudando a nuestros clientes
              a construir una presencia sólida y auténtica en el mercado. Desde nuestra fundación, hemos colaborado con empresas de diversos sectores,
              adaptando nuestras soluciones para impulsar su visibilidad y fortalecer su identidad de marca. <br/> <br/>

              Nos enfocamos en crear conexiones genuinas entre las marcas y sus audiencias, integrando análisis de datos, creatividad y contenido relevante.
              En Media4Sports trabajamos como socios estratégicos para llevar a nuestros clientes al siguiente nivel en un entorno digital competitivo y en constante cambio.
            </p>
          </motion.div>

          <motion.img
            initial={{ opacity: 0, x: 50 }}
            animate={hasViewedSection1 ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }} // Animación condicional
            transition={{ duration: 1 }}
            src={teamPhoto}
            alt="Cohete"
            className="h-60 md:h-80 md:ml-32 mb-5 mt-5"
            loading="lazy"
          />
        </div>
      </section>

      {/* Misión, Visión y Valores */}
      <section className="py-16 bg-gray-100" ref={refSection2}>
        <div className="container mx-auto text-center">
          <motion.h2
            initial={{ opacity: 0, y: 50 }}
            animate={hasViewedSection2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 1 }}
            className="font-raleway text-4xl font-extrabold mb-12"
          >
            Nuestra Misión, Visión y Valores
          </motion.h2>

          <div className="flex flex-wrap justify-center gap-8">
            {/* Misión */}
            <motion.div
              className="bg-white shadow-lg rounded-3xl p-6 w-80"
              initial={{ opacity: 0, y: 50 }}
              animate={hasViewedSection2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 1, delay: 0.2 }}
            >
              <img src={missionIcon} alt="Misión" className="h-16 mx-auto mb-4" loading="lazy"/>
              <h3 className="font-raleway text-2xl font-bold mb-2">Nuestra Misión</h3>
              <p className="text-gray-600">
                Potenciar a nuestros clientes con estrategias digitales efectivas y auténticas, conectándolos de manera real y memorable con sus audiencias.              </p>
            </motion.div>

            {/* Visión */}
            <motion.div
              className="bg-white shadow-lg rounded-3xl p-6 w-80"
              initial={{ opacity: 0, y: 50 }}
              animate={hasViewedSection2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 1, delay: 0.4 }}
            >
              <img src={visionIcon} alt="Visión" className="h-16 mx-auto mb-4" loading="lazy"/>
              <h3 className="font-raleway text-2xl font-bold mb-2">Nuestra Visión</h3>
              <p className="text-gray-600">
                Convertirnos en referentes de la innovación digital, ayudando a marcas de todas las industrias a crear vínculos profundos con sus consumidores.              </p>
            </motion.div>

            {/* Valores */}
            <motion.div
              className="bg-white shadow-lg rounded-3xl p-6 w-80"
              initial={{ opacity: 0, y: 50 }}
              animate={hasViewedSection2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ duration: 1, delay: 0.6 }}
            >
              <img src={valuesIcon} alt="Valores" className="h-16 mx-auto mb-4" loading="lazy"/>
              <h3 className="font-raleway text-2xl font-bold mb-2">Nuestros Valores</h3>
              <p className="text-gray-600">
                Integridad, creatividad, trabajo en equipo y dedicación al éxito de cada cliente son los valores que guían nuestra labor diaria.              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-white text-center">
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.8 }}
          className="text-3xl font-bold mb-6"
        >
          ¿Estás listo para potenciar tu estrategia de marketing?
        </motion.h2>
        <Link to="/contact">
          <motion.button
            aria-label="Contacta a Media4Sports"
            whileHover={{ scale: 1.1, transition: { duration: 0.5 } }}
            whileTap={{ scale: 0.9, transition: { duration: 0.5 } }}
            className="bg-rose-600 hover:bg-rose-700 text-white font-lightbold py-4 px-8 rounded-full"
          >
            Contáctanos
          </motion.button>
        </Link>
      </section>
    </div>
  );
}

export default History;
