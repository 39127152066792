import React from 'react';
import logo from '../images/webp/roundlogo.webp';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-white">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <Link to="/" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse" aria-label="Media4Sports Logo">
            <img src={logo} className="h-12 mb-2" alt="Media4Sports Logo" />
            <span className="font-nunito font-extrabold self-center text-2xl whitespace-nowrap">Media4Sports</span>
          </Link>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium font-nunito text-gray-500 sm:mb-0 space-x-4">
            <li>
              <Link to="/history" className="hover:underline">About</Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="hover:underline">Política de Privacidad</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions" className="hover:underline">Términos y Condiciones</Link>
            </li>
            <li>
              <Link to="/contact" className="hover:underline">Contacto</Link>
            </li>
          </ul>
        </div>
        <hr className="my-4 border-gray-200 sm:mx-auto lg:my-4" />
        <span className="block text-sm text-gray-500 sm:text-center">
          © 2024 <Link to="/" className="font-nunito hover:underline">Media4Sports</Link>. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}

export default Footer;